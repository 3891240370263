import React from "react";
import { useParams } from 'react-router';
import { JWTDecode } from '../../Utilities';
import Axios from "../Auth/Axios";
import Button from "../Button";
import styles from "./State.module.css";
import { StateType } from "./Types";


const State = (props: StateType) => {
  const params= useParams()
  const game_id = params.game_id
  const user = JWTDecode('accessToken');

  const handleAddButtonClick: React.MouseEventHandler<HTMLDivElement> = () => {

    Axios.post(`/games/${game_id}/plate`, {state: props.id, added_by: user.id, game_id: game_id})
      .then( () => {
        window.location.reload();
      })
  }

  return (
    <div className={styles.plate}>
      {
        <div className={styles.plateTitle}>
          {props.name}
          <Button onClick={handleAddButtonClick} text="Found It" />
        </div>
      }
    </div>
  );
};

export default State;
