import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../Components/Auth/Axios";
import style from "./LoginLayout.module.css";

export const LoginLayout = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    Axios.post('/auth/login', {email: email, password: password})
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        return res.data
      })
      .then( (data) => {
        try {
          Axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        } catch (error) {
          console.log(error);
        }
      })
      .finally(() => {
        navigate("/user/games", {replace: true});
      })
  }

return (
  <div className={style.logForm}>
    <h2>Login to your account</h2>
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <input type="submit" className={style.btn} value="Login" />
    </form>
  </div>
)
};