import { DateTime } from "luxon";
import React from "react";
import styles from "./Plate.module.css";
import { PlateType } from "./Types";


const Plate = (props: PlateType) => {
  // console.log(props.state);
  // props.state = props.state.replace('-', ' ');
  const capitalizeWords = (str: string) => {
    return str
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const name = `${props.found_by.first_name} ${props.found_by.last_name}`;
  const date = DateTime.fromISO(props.createdAt).toLocaleString(DateTime.DATE_MED);

  return (
    <div className={styles.plate}>
      {props.state && <div className={styles.plateTitle}>{capitalizeWords(props.state)}</div>}
      <div className={styles.plateBody}>{name} on {date}</div>
    </div>
  );
};

export default Plate;