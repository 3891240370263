import React from "react";
import State from "../../Components/State/State";
import { StateType } from "../../Components/State/Types";
import style from "./LostLayout.module.css";

const LostLayout = (props: {states: StateType[]}) => {
  const { states } = props;

  const stateComponents = states.map((state) => {
    return <State key={state.id} {...state} />;
  });

  return (
    <div className={style.wrapper}>
      <h2>Missing Plates ({stateComponents.length})</h2>
      {stateComponents}
    </div>
  )
};

export default LostLayout;