import React from "react";
import { Link } from "react-router-dom";
import { UserAuthenticated } from "../../Components/Auth";
import style from "./Header.module.css";

export const Header = () => {


  return (
    <div className={style.container}>
      <div className={style.logo}>PL8-Finder</div>

      { !UserAuthenticated() &&
        <a href="" className={style.item}>Create Account</a>
      }

      { !UserAuthenticated() &&
        <Link className={style.item} to="/login">Login</Link>
      }

      { UserAuthenticated() &&
        <Link className={style.item} to="/user/games">Games</Link>
      }

      { UserAuthenticated() &&
        <Link className={style.item} to="/logout">Logout</Link>
      }

    </div>
  )
};