import React from "react";
import { LoginLayout } from "../../Layout/AuthLayout";
import MainLayout from "../../Layout/MainLayout";

export const Login = () => {

  return (
    <MainLayout>
      <LoginLayout />
    </MainLayout>
  )
};
