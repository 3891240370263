import React from "react";
import StateMapComponent from "../../Components/MapState/MapState";
import { StateType } from "../../Components/State/Types";
import styles from "./MapLayout.module.css";


const MainLayout = (props: {lost: StateType[], found: StateType[]}) => {
  const { lost, found } = props;

  const lostStateMapComponents = lost.map((state) => {
    state.status = 'lost';
    return <StateMapComponent key={state.id} {...state} />;
  });

  const foundStateMapComponents = found.map((state) => {
    state.status = 'found';
    return <StateMapComponent key={state.id} {...state} />;
  });

  return (
    <svg id="map" className={styles.map} viewBox="174 100 959 593" >
        <g>
          {lostStateMapComponents}
          {foundStateMapComponents}
        </g>
      </svg>
  )
};

export default MainLayout;
