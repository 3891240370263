import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/", {replace: true});
    },2000)
  });

  return (
    <MainLayout>
      <h2>Logging out</h2>
    </MainLayout>
  )
};
