import React from "react";
import MainLayout from '../MainLayout/MainLayout';

export const LandingLayout = () => {

  return (
    <MainLayout>
      <p>Welcome to pl8-finder</p>
      <p>Log in or create an account to continue</p>
    </MainLayout>
  )
};