import React from "react";
import Axios from "../Auth/Axios";
import Button from "../Button";

export const GameNew = () => {
  const handleNewGameClick: React.MouseEventHandler<HTMLDivElement> = () => {

    Axios.post('/games/')
    .then((res) => {
      window.location.href = `/game/${res.data.id}`;
    })
  }

  return (
    <div>
      <Button onClick={handleNewGameClick} text="Create New Game" />
    </div>
  );
};