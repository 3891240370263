import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Axios from "../../Components/Auth/Axios";
import MainLayout from "../../Layout/MainLayout";

export const GameShare = () => {
  const navigate = useNavigate();

  const host = process.env.REACT_APP_API_URL;

  const params= useParams()
  const game_id = params.game_id


  useEffect(() => {
    // Validate the game_id and redirect to the Game404 view if its not valid
    Axios.get(`/games/${game_id}`)
      .then(() => {
        return;
      }).catch( () => {
        navigate(`/game/${game_id}/404`, {replace: true});
      });

    // get the found plates for this game
    Axios.post(`/games/${game_id}/share`)
      .then(() => {
        navigate(`/game/${game_id}`, {replace: true});
      });
  }, [host]);


  return (
    <MainLayout>
      Adding Shared Game
    </MainLayout>
  )
};