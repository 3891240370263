import React from "react";
import styles from "./Button.module.css";

interface PropType {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  text: string;
  gameId?: string;
}

const Button = (props: PropType) => {
  return (
    <div onClick={props.onClick} className={styles.button} data-id={props.gameId}>
      {props.text}
    </div>
  );
};

export default Button;
