import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserAuthenticated } from './User';

export function ProtectedRoutes() {
  try {
    const authenticated = UserAuthenticated();

    if (!authenticated) {
      return  <Navigate to="/login" />
    }

    return authenticated ? <Outlet /> : <Navigate to="/" />;
  } catch (error) {
    return <Navigate to="/" />;
  }
}