import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import { GameType } from "../../Components/Game/Types";
// import style from "./Games.module.css";
import { DateTime } from 'luxon';
import styles from "../../Components/Game/Game.module.css";



export const GamesLayout = (props: {games: GameType[]}) => {
  const navigate = useNavigate();

  const { games } = props;

  const handleGameClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    const attr = target.getAttribute("data-id");
    console.log(target);

    navigate(`/game/${attr}`, {replace: true});
  }

  const gameComponents = games.map((game) => {

    const completedString = (game.completedAt)
      ? 'COMPLETED ' + DateTime.fromISO(game.completedAt).toLocaleString(DateTime.DATETIME_MED)
      : 'IN PROGRESS';

    const buttonString = (game.completedAt)
      ? 'View'
      : 'Play';

    return (
      <div key={game.id} className={styles.game}>
      {
        <div className={styles.gameTitle}>
          {completedString}
          <Button onClick={handleGameClick} gameId={game.id} text={buttonString} />
        </div>
      }
      </div>
    )
  });

  return (
    <div className={styles.wrapper}>
      <h2>Found Games ({gameComponents.length})</h2>
      {gameComponents}
    </div>
  )
};