import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Axios from "../../Components/Auth/Axios";
import FoundLayout from "../../Layout/FoundLayout";
import LostLayout from "../../Layout/LostLayout";
import MainLayout from "../../Layout/MainLayout";
import MapLayout from "../../Layout/MapLayout";
import QRCodeLayout from "../../Layout/QRCodeLayout";
import States from "../../states.json";
import { PlateType } from "../Plate/Types";
import { StateType } from "../State/Types";



export const Game = () => {
  const navigate = useNavigate();

  const host = process.env.REACT_APP_API_URL;

  const params= useParams()
  const game_id = params.game_id

  const [foundPlates, setFoundPlates] = useState<PlateType[]>([]);
  const [lostStates, setLostStates] = useState<StateType[]>([]);
  const [foundStates, setFoundStates] = useState<StateType[]>([]);

  const sortingHat = (states: StateType[], plates: PlateType[]) => {
    // make array of state names
    const foundPlates = plates.map((plate) => {
      return plate.state;
    });

    const lost = states.filter((state: StateType) => {
      return !foundPlates.includes(state.id)
    });

    const found = states.filter((state) => {
      return foundPlates.includes(state.id)
    });

    return { found: found, lost: lost};
  }

  const determineAndSetLostStates = (states: StateType[], plates: PlateType[]) => {
    const { lost, found } = sortingHat(states, plates);

    setLostStates(lost);
    setFoundStates(found);
  }

  const share_link = `URL:${window.location.origin}/game/${game_id}/share`;

  useEffect(() => {
    // Validate the game_id and redirect to the Game404 view if its not valid
    Axios.get(`/games/${game_id}`)
      .then(() => {
        return;
      }).catch( () => {
        navigate(`/game/${game_id}/404`, {replace: true});
      });

    // get the found plates for this game
    Axios.get(`/games/${game_id}/plates`)
      .then((res) => {
          res.data.plates.sort((a: PlateType, b: PlateType) => a.state.localeCompare(b.state));

          setFoundPlates(res.data.plates);
          determineAndSetLostStates(States,res.data.plates);

      });
  }, [game_id, host]);


  return (
    <MainLayout>
      <MapLayout lost={lostStates} found={foundStates}></MapLayout>
      <LostLayout states={lostStates} />
      <FoundLayout plates={foundPlates} />
      <QRCodeLayout link={share_link} />
    </MainLayout>
  )
};