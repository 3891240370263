import React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Login, Logout, ProtectedRoutes } from "./Components/Auth";
import { Game, GameNew, GameShare } from "./Components/Game";
import { User, UserGames } from "./Components/User";
import { LandingLayout } from "./Layout/LandingLayout";
import MainLayout from "./Layout/MainLayout";



function App() {

  function Game404(){
    return (
      <MainLayout>
        <div>
          <h1>Game does not exist</h1>
        </div>
      </MainLayout>
    );
  }

  return (
    <div>

    <Helmet>
      <title>Pl8Finder</title>
    </Helmet>

    <Routes>
      <Route path="/" element={<LandingLayout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="/game/:game_id" element={<Game />} />
        <Route path="/game/:game_id/404" element={<Game404 />} />
        <Route path="/game/new" element={<GameNew />} />
        <Route path="/game/:game_id/share" element={<GameShare />} />
      </Route>

      <Route element={<ProtectedRoutes />}>
        <Route path="/user" element={<User />} />
        <Route path="/user/games" element={<UserGames />} />
      </Route>
    </Routes>
    </div>
  );
}

export default App;
