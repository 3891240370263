import axios from 'axios';
import jwt_decode from 'jwt-decode';

const host = process.env.REACT_APP_API_URL;

export const GetUser = () => {
  const userJson = localStorage.getItem('user');

  if (userJson){
    return JSON.parse(userJson);
  }else {
    throw new Error("JWT could not be decoded. Is the user logged in?");
  }
}

export function JWTDecode(tokenName: string) {
  const userJson = localStorage.getItem('user');

  if (userJson){
    const user = JSON.parse(userJson);

    // eslint-disable-next-line
    const decodedJwt: any = jwt_decode(user[tokenName]);
    return decodedJwt;
  }else {
    throw new Error("JWT could not be decoded");
  }
}

export function TokenDateValid() {

  try {
    const decodedJwt = JWTDecode('accessToken')
    const now = Math.round(Date.now() / 1000)

    if (decodedJwt.exp < now) {
      return  false
    }
    return true
  } catch (error) {
    return false
  }
}

export function RefreshTokenDateValid() {
  try {
    const decodedJwt = JWTDecode('refreshToken')
    const now = Math.round(Date.now() / 1000)

    if (decodedJwt.exp < now) {
      return  false
    }
    return true
  } catch (error) {
    return false
  }
}

export function DestroyUserSession() {
  localStorage.clear();
  setTimeout(() => {
    window.location.href = '/login';
  },1000)
}

export async function RefreshTokens() {
  const userJson = localStorage.getItem('user');

  if(userJson){
    const user = JSON.parse(userJson)

    console.log("REFRESH TOKENS: Making API Request");

    const axiosv = axios.create({
      baseURL: `//${host}`
    });

    //TODO: this probably cant use the impl because it causes infinate recursion
    return await axiosv.post('/auth/refresh',{refreshToken: user.refreshToken})
      .then( (res) => {
        // set the localstorage token
        localStorage.setItem('user', JSON.stringify(res.data));
        return res.data.accessToken;
      })
      .catch(() => {
        DestroyUserSession();
      });
  }else{
    throw new Error("User not set");
  }
}
