import React from "react";
import QRCode from "react-qr-code";
import style from "./QRCodeLayout.module.css";



const QRCodeLayout = (props: {link: string}) => {
  const { link } = props;

  return (
    <QRCode className={style.code} value={link} />
  )
};

export default QRCodeLayout;