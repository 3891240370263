import React from "react";
import Plate from "../../Components/Plate/Plate";
import { PlateType } from "../../Components/Plate/Types";
import style from "./FoundLayout.module.css";


const FoundLayout = (props: {plates: PlateType[]}) => {
  const { plates } = props;

  const plateComponents = plates.map((plate) => {
    return <Plate key={plate.id} {...plate} />;
  });

  return (
    <div className={style.wrapper}>
      <h2>Found Plates ({plateComponents.length})</h2>
      {plateComponents}
    </div>
  )
};

export default FoundLayout;