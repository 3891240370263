import React from "react";
import { Header } from "../../Components/Header";
import styles from "./MainLayout.module.css";

const MainLayout = (props: React.PropsWithChildren) => {
  return (
    <div className={styles.wrapper}>
      <Header />
      {props.children}
    </div>
  );
};

export default MainLayout;
