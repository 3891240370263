import React, { useEffect, useState } from 'react';
import Axios from "../../Components/Auth/Axios";
import MainLayout from "../../Layout/MainLayout";
import { GamesLayout } from "../../Layout/UserLayout";
import { JWTDecode } from '../../Utilities';
import { GameType } from "../Game/Types";

export const UserGames = () => {
  const host = process.env.REACT_APP_API_URL;
  const user = JWTDecode('accessToken');

  const [games, setGames] = useState<GameType[]>([]);

  useEffect(() => {
    // get the games associate to the authenticated user
    Axios.get(`/users/${user.id}/games/`)
    .then((res) => {
      setGames(res.data);
    })
    .catch( (e) => {
      throw new Error("Games could not be fetched", {cause: e});
    });
  }, [host]);


  return (
    <MainLayout>
      <GamesLayout games={games}/>
    </MainLayout>
  )
};