import React from "react";
import { StateType } from "../State/Types";
import styles from "./MapState.module.css";


const MapStateComponent = (props: StateType) => {
  const classes = (props.status === "lost") ? styles.state : styles.collected;
  return (
    <path className={classes} id={props.id} d={props.svg} />
  );
};

export default MapStateComponent;
